import { useEffect, useState } from "react";

const Footer = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/count`)
      .then((result) => {
        return result?.json();
      })
      .then((data) => {
        if (data.success) {
          setCount(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="Footer text-muted">
      <strong className="float-end">{count} stars</strong>
    </div>
  );
};

export default Footer;
