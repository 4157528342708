import { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";

function ReportModal(props: { show: boolean; onHide: () => void }) {
  const [data, setData] = useState("");

  const sendReport = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((result) => {
        return result?.json();
      })
      .then((data) => {
        if (data.success) {
          swal("Thank you!", "I'll get right on this!", "success");
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          swal("Uh oh!", "Something went wrong.", "error");
          setData('');
        }
      })
      .catch((error) => {
        swal("Uh oh!", "Something went wrong", "error");
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            See a way to improve this app?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="messageForm.textarea">
              <Form.Label>Comment (Required)</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                minLength={3}
                maxLength={255}
                onChange={({ target: { value } }) => setData(value)}
                placeholder="Must be at least 3 characters long"
                value={data}
              />
              <Form.Text className="text-muted">{data.length} / 255</Form.Text>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                You need a comment
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="float-end"
              disabled={!data || data.length < 3}
              variant="primary"
              onClick={() => sendReport()}
            >
              Report
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReportModal;
