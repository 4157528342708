import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function MessageModal(props: {
  show: boolean;
  onHide: () => void;
  data: { message: string; author: string; id: string };
}) {
  const [flagged, setFlagged] = useState(false);

  const flagMessage = (id: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/flag`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((result) => {
        return result?.json();
      })
      .then((data) => {
        if (data.success === true) {
          setFlagged(true);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            For you...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <figure>
            <blockquote className="blockquote">
              <p>{props.data.message}</p>
            </blockquote>
            <figcaption className="blockquote-footer">
              From <cite title="Source Title">{props.data.author}</cite>
            </figcaption>
          </figure>
          <Button
            className="float-end"
            hidden={flagged}
            variant="danger"
            onClick={() => flagMessage(props.data.id)}
          >
            Report{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-flag-fill"
              viewBox="0 0 16 16"
            >
              <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
            </svg>
          </Button>
          <strong className="text-success" hidden={!flagged}>
            We got it!
          </strong>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MessageModal;
