import { useState } from "react";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import AboutModal from "../AboutModal/AboutModal";
import ReportModal from "../ReportModal/ReportModal";
import StarModal from "../StarModal/StarModal";

const NavbarComponent = () => {
  const [starModalShow, setStarModalShow] = useState(false);
  const [aboutModalShow, setAboutModalShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  return (
    <>
      <Navbar expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Darkest At Night</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Link onClick={() => setAboutModalShow(true)}>About</Nav.Link>
              <Nav.Link href="https://www.paypal.com/donate/?hosted_button_id=YNCVZJJAHR96L">
                Donate
              </Nav.Link>
              <NavDropdown title="Socials" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://www.tiktok.com/@darkestatnight">
                  Tiktok
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => setReportModalShow(true)}>
                Report an issuse
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Button
              onClick={() => setStarModalShow(true)}
              variant="outline-light"
            >
              Add a Star
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <StarModal show={starModalShow} onHide={() => setStarModalShow(false)} />
      <AboutModal
        show={aboutModalShow}
        onHide={() => setAboutModalShow(false)}
      />
      <ReportModal
        show={reportModalShow}
        onHide={() => setReportModalShow(false)}
      />
    </>
  );
};

export default NavbarComponent;
