import React, { useEffect, useState } from "react";
import MessageModal from "../MessageModal/MessageModal";

const Star = (props: IStarProps) => {
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [starType, setStarType] = useState("");
  const [starStyle, setStarStyle] = useState({});
  const modalData = props.message;

  useEffect(() => {
    const STAR_TYPES = [
      "star-1",
      "star-2",
      "star-3",
      "star-4",
      "star-5",
      "star-6",
    ];
    const random = Math.floor(Math.random() * STAR_TYPES.length);
    const starType = `stars ${STAR_TYPES[random]}`;
    const topLocation = `${Math.floor(Math.random() * 88) + 5}%`;
    const leftLocation = `${Math.floor(Math.random() * 96) + 1}%`;

    setStarType(starType);
    setStarStyle({ top: topLocation, left: leftLocation });
  }, []);

  return (
    <>
      <svg
        className={starType}
        style={starStyle}
        onClick={() => setMessageModalShow(true)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 479.24 390.58"
      >
        <path
          fill="white"
          className="b"
          d="m239.22,389.49c-2.22-6.03-4.9-49.76-7.28-88.58-.96-15.62-1.95-31.77-2.9-45.46l-.03-.37-.36-.08c-21.06-4.76-37.42-21.5-41.68-42.66l-.08-.38L1.72,201.05c9.1-2.05,61.93-4.37,108.93-6.42,26.93-1.18,54.78-2.4,75.84-3.6l.39-.02.08-.38c4.12-20.58,19.93-37.22,40.28-42.39l.35-.09L239.17,1.16c2.34,6.5,5.64,53.2,8.56,94.65,1.26,17.83,2.56,36.26,3.77,51.41l.03.37.37.08c20.69,4.25,37.27,20.28,42.24,40.83l.09.36,183.45,10.21c-8.64,2.31-62.37,5.8-110.13,8.91-25.75,1.67-52.37,3.41-72.71,4.98l-.37.03-.08.36c-4.57,20.66-20.86,37.01-41.51,41.66l-.35.08-13.31,134.4Z"
        />
        <path
          fill="light-grey"
          d="m239.42,4.43c2.27,12.83,5.31,55.88,7.82,91.4,1.26,17.83,2.56,36.27,3.77,51.43l.06.75.74.15c20.5,4.22,36.93,20.1,41.85,40.46l.17.72.74.04,178.87,9.96c-15.88,2.29-65.15,5.49-105.93,8.15-25.75,1.68-52.37,3.41-72.71,4.98l-.74.06-.16.72c-4.53,20.47-20.67,36.67-41.13,41.27l-.7.16-.07.72-12.97,131c-2.07-12.01-4.55-52.29-6.59-85.53-.96-15.62-1.95-31.77-2.9-45.46l-.05-.74-.73-.16c-20.87-4.71-37.08-21.31-41.3-42.27l-.15-.76-.77-.05L6.5,200.83c16.15-1.85,62.49-3.88,104.18-5.71,26.93-1.18,54.78-2.4,75.83-3.6l.77-.04.15-.76c4.08-20.39,19.74-36.88,39.91-42.01l.69-.18.06-.71L239.42,4.43m-.47-4.43c-.06,0-.13.03-.19.09l-11.65,147.65c-20.52,5.21-36.46,21.87-40.64,42.78C121.73,194.22-3.46,197.92.07,201.45l186.4,10.99c4.3,21.36,20.88,38.26,42.06,43.05,3.62,52.01,7.52,135.1,10.89,135.1.07,0,.13-.03.2-.1l13.37-134.99c20.86-4.69,37.27-21.14,41.89-42.03,64.55-4.99,187.81-11.29,184.29-14.81l-184.54-10.27c-5.01-20.71-21.65-36.89-42.62-41.2C247.63,92.52,242.44,0,238.95,0h0Z"
        />
      </svg>

      <MessageModal
        show={messageModalShow}
        onHide={() => {
          setMessageModalShow(false);
        }}
        data={modalData}
      />
    </>
  );
};

interface IStarProps {
  message: {
    message: string;
    author: string;
    id: string;
  };
}

export default Star;
