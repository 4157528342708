import { useEffect, useState } from "react";

import NavbarComponent from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Star from "./components/Star/Star";

import "./App.css";
import { Col, Row } from "react-bootstrap";
import SplashScreen from "./components/SplashScreen/SplashScreen";

function App() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/getAllMessages`)
      .then(async (response) => {
        if (response.status === 200) {
          return await response.json();
        }
      })
      .then((results) => {
        const messages = results.data.map(
          (message: {
            message: string;
            flagged: boolean;
            author: string;
            _id: string;
          }) => {
            return {
              message: message.message,
              flagged: message.flagged,
              author: message.author || "Anonymous",
              id: message["_id"],
            };
          }
        );

        setMessages(messages);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="container-fluid App">
        <NavbarComponent />
        <SplashScreen />
        {messages.length > 0 ? (
          messages.map((message, i) => {
            return <Star key={i} message={message} />;
          })
        ) : (
          <Row className="loading-text justify-content-center align-items-center">
            <Col sm={8}>
              <h1>
                Take a deep breath while we grab everything from our database!
              </h1>
            </Col>
          </Row>
        )}
        <Footer />
      </div>
    </>
  );
}

export default App;
