import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";

function StarModal(props: { show: boolean; onHide: () => void }) {
  const [author, setAuthor] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    setValidated(true);

    try {
      const data = {
        author: author || "Anonymous",
        message: message,
      };
      await fetch(`${process.env.REACT_APP_API_URL}/api/addMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          if (result.success) {
            swal(
              "You did it!",
              "Thank you for possibly saving a life!",
              "success"
            );
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          swal("Uh oh!", "Something went wrong", "error");
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        });
    } catch (error) {
      console.error("e", error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Make your star
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Author</Form.Label>
            <Form.Control
              type="input"
              placeholder="A name to author this message"
              onChange={({ target: { value } }) => setAuthor(value)}
              value={author}
            />
            <Form.Text className="text-light">
              We will just put Anonymous if you don't want to leave a name.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="messageForm.textarea">
            <Form.Label>Message (Required)</Form.Label>
            <Form.Control
              required
              as="textarea"
              rows={3}
              minLength={3}
              maxLength={255}
              onChange={({ target: { value } }) => setMessage(value)}
              placeholder="Must be at least 3 characters long"
              value={message}
            />
            <Form.Text className="text-light">{message.length} / 255</Form.Text>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              You need a message
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            className="float-end"
            disabled={!message || message.length < 3}
            variant="primary"
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default StarModal;
