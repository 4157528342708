import { Modal } from "react-bootstrap";


function AboutModal(props: { show: boolean; onHide: () => void }) {
    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Create light, in a dark world
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              I had the idea for this app on a night I almost killed myself. I was depressed and my world was crashing down.
              As I laided in my bed hoping I did not survive the night, I kept thinking of what I would have said to someone in my current
              situation to keep me alive until tomorrow. 
            </p>
            <p>
              I started to tell myself, "You are enough", "Today is a bad day, but tomorrow may not be... don't you wanna see?", "Please, live one more day!"
              Tears flooded my pillow that night and I survived. It was dark and as the sun rose in my room, I realized that I had
              another chance to live... another chance to <b>RESOVLE</b> to live. 
            </p>
            <p>
              I hope that you reading this... who may need a reason to survive today... find it here.
              And maybe leave a note for someone else to find a reason too. 
            </p>
            <p>Thank you for being a part of this world!</p>
          </Modal.Body>
        </Modal>
      );
    }
    
    export default AboutModal;