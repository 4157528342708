import React from "react";

import "./SplashScreen.css";
import { Col, Row } from "react-bootstrap";

const SplashScreen = () => {
  return (
    <>
      <Row className="justify-content-center align-items-center">
        <Col id="splash" xs="11" sm="10" md="8">
          <h1 className="text-center">
            <blockquote style={{ color: "whitesmoke" }}>
              Instead of being the Sun that brightens someone's day.
              <br></br>
              Be a star that shines on their darkest nights.
            </blockquote>
          </h1>
        </Col>
      </Row>
    </>
  );
};

export default SplashScreen;
